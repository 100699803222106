@import 'variables';

.Contact {
    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__header {
        display: none;
    }
    &__persons-container {
        width: 100%;
        height: min(779px, 779/1920*100vw);
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 26px;
    }
    &__person-wrapper {
        position: relative;
    }
    &__person {
        width: min(518px, 518/1920*100vw);
        height: min(779px, 779/1920*100vw);
        // width: 518/1080*100vmin;
        // height: 779/1080*100vmin;
        position: absolute;
        &--left {
            right: 0;
        }
        &-info {
            width: 100%;
            top: min(216px, 216/1920*100vw);
            position: absolute;
            font-size: min(16px, 16/1920*100vw);
            font-weight: 600;
            letter-spacing: -0.16px;
            margin: 0;
            p {
                margin: 0 0;
            }
            a { color: inherit; }
            &-left {
                // left: min(-42px, -42/1920*100vw);
                left: -8%;
            }
            &-right {
                // left: 476/1080*100vmin;
                left: 92%;
            }
            &-header {
                color: $red;
            }
            &-name {
                font-size: min(30px, 30/1920*100vw);
                padding: min(16px, 16/1920*100vw) 0;
            }
        }
        &-image {
            width: 100%;
            height: 100%;
        }
    }
}

@media (max-width: 992px) {
    .Contact {
        height: calc(100vh - var(--navHeight) - 65/360*100vw);
        &__container {
            height: calc(100vh - 65/360*100vw);
            display: grid;
            grid-template-rows: auto 1fr;
            grid-template-columns: 1fr;
        }
        &__header {
            display: block;
            font-size: min(30px, 30/360*100vw);
            font-weight: 500;
            letter-spacing: -0.75px;
            text-align: center;
            margin: min(35px, 35/360*100vw) 0;
        }
        &__persons-container {
            height: 100%;
            padding-bottom: 38/780*100vh;
            column-gap: 8/360*100vw;
            align-items: center;
        }
        &__person-wrapper {
            height: 435/360*100vw;
        }
        &__person {
            width: 176/360*100vw;
            height: 265/360*100vw;
            &--right {
                bottom: 0;
            }
            &-info {
                top: 44/265*100%;
                font-size: min(16px, 16/360*100vw);
                font-weight: 400;
                &-left {
                    left: 160/360*100vw;
                }
                &-right {
                    left: -139/360*100vw;
                    top: auto;
                    bottom: 44/265*100%;
                }
                &-name {
                    font-size: min(19px, 19/360*100vw);
                    padding: min(13px, 13/360*100vw) 0 min(8px, 8/360*100vw);
                    font-weight: 500;
                }
            }
        }
    }
}

$ratio: 100vw/100vh;
@if $ratio > 0.6 and $ratio < 1.2 {
    .Contact {
        height: 100vh;
        &__container {
            height: calc(100% + var(--navHeight));
        }
    }
}
// @media (max-width: 380px) and (min-height: 500px) and (max-height: 650px) {
//     .Contact {
//         height: calc(100vh - var(--navHeight));
//         &__container {
//             height: calc(100vh - var(--navHeight) + 65/360*100vw /2);
//         }
//     }
// }
