@import "variables";

.Navbar {
    width: 100%;
    height: 94px;
    // padding: 24px 160/1920*100vw;
    padding: 0 120/1920*100vw;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.4px;
    // background: $black;
    background-image: linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0));
    background-size: 100% 0%;
    background-repeat: no-repeat;
    color: #fff;
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 9;
    transition: $transition;
    a {
        color: #fff;
        transition: all .3s ease-in-out;
        span {
            position: relative;
            &::before {
                content: "";
                width: 0;
                height: 2px;
                left: 0;
                bottom: -16px;
                position: absolute;
                background-color: $red;
                transition: all .3s ease-in-out;
            }
        }
        &:hover {
            color: $red;
            span::before {
                width: 100%;
            }
        }
    }
    &.active {
        &-AboutUs .Navbar__list-item--AboutUs {
            a {
                color: $red;
                span::before { width: 100%; }
            }
        }
        &-CaseStudy .Navbar__list-item--CaseStudy {
            a {
                color: $red;
                span::before { width: 100%; }
            }
        }
        &-Clients .Navbar__list-item--Clients {
            a {
                color: $red;
                span::before { width: 100%; }
            }
        }
        &-OthersAboutUs .Navbar__list-item--OthersAboutUs {
            a {
                color: $red;
                span::before { width: 100%; }
            }
        }
        &-Foundation .Navbar__list-item--Foundation {
            a {
                color: $red;
                span::before { width: 100%; }
            }
        }
        &-Contact .Navbar__list-item--Contact {
            a {
                color: $red;
                span::before { width: 100%; }
            }
        }
    }
    &__hamburger {
        display: none;
        position: absolute;
        width: 24px;
        height: 16px;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        cursor: pointer;
        flex-direction: column;
        gap: 5px;
        &.active {
            .Navbar__hamburger-line {
                transform: rotate(-45deg);
                &--top {
                    transform: rotate(45deg);
                }
            }
        }
    }
    &__hamburger-line {
        width: 100%;
        height: 2px;
        background-color: #fff;
        transition: transform .3s ease-in-out;
        &--top {
            transform-origin: 3px 4px;
        }
        &--bottom {
            transform-origin: 4px -1px;
        }
    }
    &__languages {
        position: absolute;
        top: 50%;
        right: min(60px, 60/1920*100vw);
        transform: translateY(-50%);
        font-size: 16px;
        font-weight: 300;
    }
    &__language {
        cursor: pointer;
        transition: .3s;
        &.active {
            color: $red;
        }
    }
    &__language-separator {
        font-size: 22px;
        transform: translateY(1px);
        font-weight: 300;
        display: inline-block;
        padding: 0 4px;
    }
    &__list {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 0;
        display: flex;
        align-items: center;
        gap: 71/1920*100vw;
        list-style: none;
        &--left {
            justify-content: end;
        }
    }
    &__list-item {
        height: 100%;
        min-height: 24px;
        a {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            text-align: center;
        }
    }
    &__logo {
        width: 226px;
        margin: 0 71/1920*100vw;
        transition: $transition;
        &--mobile {
            display: none;
        }
    }
    
}
.Navbar.scrolled {
    transition: $transition;
    height: var(--navHeight);
    background-size: 100% 100%;
    .Navbar__list {
        transition: $transition;
    }
    .Navbar__logo {
        width: 200px;
    }
}

@media (max-width: 1370px) {
    .Navbar {
        &__languages {
            right: min(16px, 16/1920*100vw);
        }
    }
}
@media (max-width: 1300px) {
    .Navbar {
        &__list {
            gap: 50/1920*100vw;
        }
        &__logo {
            width: 200px;
            margin: 0 50/1920*100vw;
        }
    }
}
@media (max-width: 992px) {
    .Navbar {
        height: 57px;
        font-weight: 300;
        &__hamburger {
            display: flex;
        }
        &__list {
            width: 100%;
            top: 99%;
            left: 0;
            position: absolute;
            background: #000;
            flex-wrap: wrap;
            align-items: start;
            gap: 0px 28px;
            clip-path: inset(0 0 100%);
            &--left {
                height: 168px;
                padding-top: 45px;
                transition: all .2s .2s linear;
            }
            &--right {
                height: 149px;
                top: calc(99% + 168px - 1px);
                padding-bottom: calc(55px - 28px);
                transition: all .2s linear;
            }
            &.showItems {
                clip-path: inset(0 0 0);
                &.Navbar__list--left {
                    transition: all .2s linear;
                }
                &.Navbar__list--right {
                    transition: all .2s .2s linear;
                }
            }
        }
        &__list-item {
            width: 100%;
            height: auto;
            span::before {
                display: none;
            }
        }
        &__logo {
            margin: 0 auto;
            width: 156px!important;
            // &--desktop {
            //     display: none;
            // }
            // &--mobile {
            //     display: block;
            //     width: 156px!important;
            // }
        }
    }
}
