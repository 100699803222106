@import 'variables';

.NiceDay {
    position: relative;
    padding: 120/1920*100%;
    z-index: 0;
    &__goodbay {
        width: 100%;
        font-size: 70px;
        font-weight: 600;  
        text-align: center; 
        padding: 0 10px;
        margin: 0 0 30px;
        color: $red;
    }
    &__image-wrapper {
        width: 100%;
        height: 87%;
    }
    &__image {
        width: 100%;
        z-index: -1;
    }
}

@media (max-width: 992px) {
    .NiceDay {
        display: none;
        &__goodbay {
            font-size: 50px;
        }
        &__image-wrapper {
            display: none;
        }
    }
}
