$black: #000;
$green: #a2fe01;
$dark-green: #41d778;
$grey: #6e6e6e;
$dark-grey: #202020;
$red: #e04245;
$transition: .4s ease-in-out;
// $navHeight: 74px;
:root {
    --navHeight: 74px;
}

@media (max-width: 992px) {
    :root {
        --navHeight: 57px;
    }
}

@mixin nav-arrow {
    top: 50%;
    position: absolute;
    transform: translate(0,-50%);
    left: 2%;
    pointer-events: auto;
    cursor: pointer;
    &--right {
        left: auto;
        right: 2%;
        transform: translate(0,-50%) scale(-1,1);
    }
    &--hover {
        pointer-events: none;
        opacity: 0;
        transition: $transition;
    }
    &:hover+.arrow-hover {
        opacity: 1;
    }
}
@mixin nav-arrow-smaller {
    width: 33px;
    transform: unset;
    &--left {
        left: 16px;
    }
    &--right {
        transform: scale(-1, 1);
        right: 16px;
    }
}
@mixin nav-dot {
    width: 13px;
    height: 13px;
    background: rgba(255, 255, 255, 0);
    border: 2px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    transition: $transition;
    font-size: 0;
    padding: 0;
    &[class*="active"] {
        background: rgba(255, 255, 255, 1);
    }
}
@mixin nav-dot-mobile {
    width: 8px;
    height: 8px;
    border-width: 1px;
}
