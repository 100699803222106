@import "variables";

.Button {
    width: 440px;
    height: 91px;
    border: 2px solid;
    font-family: "LeagueSpartan";
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    cursor: pointer;
    background: 50px 100px;
    transition: all .3s ease-in-out;
    position: relative;
    user-select: none;
    z-index: 1;
    &::before {
        content: '';
        width: 0;
        height: calc(100% + 4px);
        position: absolute;
        left: -2px;
        top: -2px;
        transition: all .3s ease-in-out;
        z-index: -1;
    }
    &:hover {
        color: $dark-grey;
        &::before {
            width: 101%;
        }
    }
    &--green {
        color: $dark-green;
        border-color: $dark-green;
        &::before {
            background-color: $dark-green;
        }
    }
}

@media (max-width: 992px) {
    .Button {
        width: 326px;
        height: 48px;
        font-size: 16px;
        letter-spacing: 0.4px;
    }
}