@import 'variables';

.CaseStudy {
    background: $black;
    height: calc(100vh - var(--navHeight));
    &__arrow {
        @include nav-arrow;
    }
    &__close {
        top: 70px;
        right: 70px;
        position: absolute;
        cursor: pointer;
        z-index: 9;
        &--hover {
            pointer-events: none;
            opacity: 0;
            transition: $transition;
        }
        &:hover+.close-hover {
            opacity: 1;
        }
    }
    &__controls {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: none;
    }
    &__slider {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        &-container {
            width: 1680/1920*100vw;
            margin: auto;
            transform-origin: center left;
        }
        .tns-ovh {
            overflow: hidden;
        }
        .tns-liveregion {
            display: none;
        }
        .tns-nav {
            position: absolute;
            // top: -9%;
            top: 109%;
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 13px;
            button {
                @include nav-dot;
            }
        }
    }
    &__slides {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__video-wrapper {
        width: 100%;
        height: calc(100vh - var(--navHeight));
        top: var(--navHeight);
        position: fixed;
        z-index: 1;
        display: flex;
        justify-content: center;
        background-color: #000;
        transform: translate(-100%);
        animation: showCase .5s ease-in-out forwards;
        video {
            width: 100%;
            height: 100%;
        }
    }
    @keyframes showCase {
        from {
            transform: translate(-100%);
        }
        to {
            transform: translate(0);
        }
    }
}

.CaseSlide {
    height: 662/1080*100vmin;
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 26px;
    color: #fff;
    overflow: hidden;
    font-size: 14px;
    p {
        margin: 0;
    }
    &__arrow {
        width: 21px;
        height: 9px;
        display: inline-block;
        margin-left: 10px;
        background: url(../../public/images/arrow-dark-green-right.png);
    }
    &__button {
        width: 89%;
        // height: 72px;
        // height: min(72px, 72/1920*100vw);
        height: min(72px, 72/1080*100vh);
        position: absolute;
        pointer-events: none;
        padding: 0;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.4px;
        color: $dark-grey;
        background-color: $dark-green;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 101%);
    }
    &__case {
        width: 100%;
        padding: 30px 30px;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        &::after {
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: -1;
            position: absolute;
            background-color: rgba(0, 0, 0, .5);
            transition: $transition;
        }
        &:hover {
            &::after {
                background-color: rgba(0, 0, 0, 0);
            }
            .CaseSlide__button {
                transform: translate(-50%) scale(1);
                opacity: 1;
            }
            .CaseSlide__type {
                transform: translate(-80%, 0);
            }
        }
    }
    &__image {
        height: 100%;
        position: absolute;
        z-index: -1;
        left: 50%!important;
        top: 50%!important;
        transform: translate(-50%,-50%);
    }
    .lazyload-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    &__teaser {
        // height: 100%;
        position: absolute;
        z-index: -1;
        left: 50%!important;
        top: 50%!important;
        transform: translate(-50%, -50%);
    }
    &__company {
        font-size: 16px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: -0.4px;
        margin-bottom: 8px!important;
    }
    &__product {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: -0.75px;
    }
    &__type {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.4px;
        color: $dark-green;
        transition: $transition;
    }
}

@media (max-width: 992px) {
    .CaseStudy {
        &__arrow {
            @include nav-arrow-smaller;
            top: 82.1%;
        }
        &__slider-container {
            width: 100%;
            margin: 8vh 0 auto;
        }
        &__close {
            width: 33px;
            top: 43px;
            right: 23px;
        }
        .tns-nav {
            gap: 9px;
            button {
                @include nav-dot-mobile;
            }
        }
    }
    .CaseSlide {
        &__button {
            // width: 326px;
            width: calc(100% - 32px);
            height: 48px;
            transform: translate(-50%);
        }
        &__case {
            padding: 16px 16px 15px + 48px;
        }
        &__teaser {
            width: auto!important;
            height: 100%!important;
            left: 50%!important;
            top: 0!important;
            transform: translate(-50%);
        }
    }
}

@media (max-width: 785px) {
    .CaseSlide {
        height: 64vh;
    }
}
