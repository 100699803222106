@import 'variables';

.Arrow {
    width: fit-content;
    position: fixed;
    left: 50%;
    bottom: 3vh;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: upDown .6s ease-in-out infinite alternate;
    transition: $transition;
    &__main {
        width: 24px;
        transition: $transition;
    }

    &.hidden {
        opacity: 0;
    }
}

@keyframes upDown {
    to {
        transform: translate(-50%, 1vh);
    }
}
