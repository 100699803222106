@import "variables";
@font-face {
    font-family: "LeagueSpartan";
    src: url("../../public/fonts/LeagueSpartan-ExtraLight.ttf") format("opentype");
    font-weight: 100;
}
@font-face {
    font-family: "LeagueSpartan";
    src: url("../../public/fonts/LeagueSpartan-Light.ttf") format("opentype");
    font-weight: 200;
}
@font-face {
    font-family: "LeagueSpartan";
    src: url("../../public/fonts/LeagueSpartan-Regular.ttf") format("opentype");
    font-weight: 300;
}
@font-face {
    font-family: "LeagueSpartan";
    src: url("../../public/fonts/LeagueSpartan-Medium.ttf") format("opentype");
    font-weight: 400;
}
@font-face {
    font-family: "LeagueSpartan";
    src: url("../../public/fonts/LeagueSpartan-SemiBold.ttf") format("opentype");
    font-weight: 500;
}
@font-face {
    font-family: "LeagueSpartan";
    src: url("../../public/fonts/LeagueSpartan-Bold.ttf") format("opentype");
    font-weight: 600;
}
@font-face {
    font-family: "LeagueSpartan";
    src: url("../../public/fonts/LeagueSpartan-ExtraBold.ttf") format("opentype");
    font-weight: 700;
}
@font-face {
    font-family: "LeagueSpartan";
    src: url("../../public/fonts/LeagueSpartan-Black.ttf") format("opentype");
    font-weight: 800;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}
html {
    scroll-behavior: smooth;
    scrollbar-color: $red white;
    scrollbar-width: thin;
}
body {
    margin: 0;
    color: $dark-grey;
    font-family: "LeagueSpartan", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ::-moz-selection { /* Code for Firefox */
        color: #fff;
        background: $dark-green;
    }
    ::selection {
        color: #fff;
        background: $dark-green;
    }
    &::after {
        content: "";
        width: 100%;
        height: 100vh;
        position: fixed;
        // border: 10px solid #fff;
        left: 0;
        top: 0;
        box-sizing: border-box;
        z-index: 9999;
        pointer-events: none;
    }
    &:has(.App.desktop) {
        &::after {
            border-right: 0;
        }
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-track {
            // background-color: $black;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $red;
        }
    }
}

a {
    text-decoration: none;
}

.container {
    width: 100%;
    height: 100vh;
    padding-top: var(--navHeight);
    top: calc(-1 * var(--navHeight));
    overflow: hidden;
    position: relative;
    &--splitted {
        display: grid;
        grid-template-columns: 50% 50%;
    }
}
.input-box {
    position: relative;
    &__error {
        font-size: min(16px, 16/1920*100vw);
        font-weight: 300;
        letter-spacing: .4px;
        color: $red;
        position: absolute;
        top: min(-26px, -26/1920*100vw);
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: .3s;
    }
}
input, textarea {
    width: 100%;
    background: none;
    border: 2px solid $dark-green;
    outline: none;
    font-family: "LeagueSpartan", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    color: #fff;
    font-size: min(16px, 16/1920*100vw);
    font-weight: 300;
    letter-spacing: .4px;
    padding: min(40px, 40/1920*100vw) min(25px, 25/1920*100vw);
    transition: .3s;
    &::placeholder {
        color: #fff;
    }
    &.invalid {
        color: $red;
        border-color: $red;
        &~.input-box__error {
            opacity: 1;
            visibility: visible;
        }
    }
    &.input-antibot {
        width: 0;
        height: 0;
        top: 0;
        left: 0;
        position: absolute;
        opacity: 0;
        cursor: default;
    }
}
.underline {
    position: relative;
    &::before {
        content: "";
        width: calc(100% + 6px);
        height: 60%;
        left: 50%;
        bottom: -3px;
        transform: translate(-50%);
        position: absolute;
        z-index: -1;
        background: $dark-green;
    }
}

@media (max-width: 992px) {
    .input-box {
        &__error {
            font-size: min(16px, 16/360*100vw);
            letter-spacing: -0.16px;
            top: min(-18px, -18/360*100vw);
        }
    }
    input, textarea {
        font-size: min(16px, 16/360*100vw);
        letter-spacing: -0.16px;
        padding: min(18px, 18/360*100vw);
    }
}
