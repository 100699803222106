@import 'variables';

.Clients {
    height: calc(100vh - var(--navHeight));
    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: var(--navHeight) 120/1920*100% 0;
    }
    &__logotypes {
        width: 100%;
        max-width: 1680px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: min(28px, 28/1920*100vw);
        row-gap: min(28px, .7vw);
    }
}

@media (min-width: 2000px) and (max-height: 1440px) {
    .Clients {
        &__logotypes {
            max-width: 1870px;
        }
    }
}
@media (min-width: 3000px) and (max-height: 1440px) {
    .Clients {
        &__logotypes {
            max-width: 100%;
            gap: min(25px, 25/1920*100vw);
            row-gap: min(16px, .7vw);
        }
    }
}
@media (max-width: 992px) {
    .Clients {
        &__container {
            padding: 0 16/360*100%;
        }
        &__logotypes {
            gap: 4/360*100vw 6/360*100vw;
        }
    }
}
@media (max-width: 500px) {
    .Clients {
        &__container {
            padding: 0 16/360*100%;
        }
        &__logotypes {
            gap: 8/780*100vh 12/360*100vw;
        }
    }
}

@media (max-width: 375px) and (max-height: 600px) {
    .Clients {
        &__logotypes {
            padding-top: 40px;
        }
    }
}
