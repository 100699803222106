@import "variables";

.App {
  width: 100%;
  overflow: hidden;
}

.policy {
  margin: 0;
  background-color: #000;
  padding-top: 2em;
  padding-right: 1em;
  padding-bottom: 2em;
  padding-left: 1em;
  font-size: 12px;
  line-height: 1;
  text-align: center;

  a:is(:link, :visited) {
    color: #fff;
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}
