@import 'variables';

.Showreel {
    padding-top: var(--navHeight);
    overflow: hidden;
    height: 100vh;
    &__button {
        position: absolute;
        left: 50%;
        top: 70%;
        transform: translate(-50%,-50%);
    }
    &__close {
        top: calc(70px + var(--navHeight));
        right: 70px;
        position: absolute;
        cursor: pointer;
        &--hover {
            pointer-events: none;
            opacity: 0;
            transition: $transition;
        }
        &:hover+.close-hover {
            opacity: 1;
        }
    }
    &__iframe-wrapper {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: translate(-100%);
        position: fixed;
        z-index: 1;
        opacity: 0;
        transition: $transition;
        &.active {
            transform: unset;
            opacity: 1;
        }
    }
    &__iframe {
        width: 100%;
        height: 100%;
    }
    &__teaser {
        width: 100%;
        left: 50%!important;
        position: absolute;
        transform: translate(-50%);
    }
}

@media (max-width: 992px) {
    .Showreel {
        &__button {
            top: 81%;
        }
        &__close {
            width: 33px;
            top: calc(43px + var(--navHeight));
            right: 23px;
        }
    }
}
