@import "variables";

.ContactForm {
  height: calc(100vh - var(--navHeight));
  color: white;

  &__background {
    display: flex;
    justify-content: space-between;
    background-image: url(../../public/images/pattern.jpg);
    width: 100%;
    height: min(780px, 780/1080 * 100vmin);
  }
  &__container {
    display: flex;
    align-items: center;
  }
  &__side {
    &--left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: min(90px, 90/1080 * 100vh) min(120px, 120/1920 * 100vw);
    }
    &--right {
      padding: min(90px, 90/1080 * 100vh) min(120px, 120/1920 * 100vw)
        min(90px, 90/1080 * 100vh) 0;
      width: min(1024px, 1024/1920 * 100vw);
    }
  }

  //left
  &__address {
    font-weight: 300;
    font-size: min(30px, 30/1920 * 100vw);
    line-height: min(35px, 35/1920 * 100vw);
    p:last-of-type {
      margin: 0;
    }
  }
  &__header {
    font-weight: 500;
    font-size: min(72px, 72/1920 * 100vw);
    line-height: min(72px, 72/1920 * 100vw);
    letter-spacing: -1.8px;
    white-space: pre-wrap;
  }

  //right
  &__button {
    // color: $dark-grey;
    width: min(437px, 437/1920 * 100vw);
    height: min(93px, 93/1080 * 100vh);
    font-size: min(28px, 28/1920 * 100vw);
    &-box {
      position: relative;
      grid-column: 1 / 3;
      height: fit-content;
    }
    &.highlight {
      transition: all 0.2s;
      background-color: $dark-green;
      color: $dark-grey;
    }
  }
  &__clause {
    grid-column: 1 / 3;
    margin: 0;
    font-weight: 300;
    font-size: min(14px, 14/1080 * 100vmin);
    line-height: min(18px, 18/1080 * 100vmin);
    letter-spacing: 0.4px;

    // height: fit-content;
    // display: -webkit-box;
    // -webkit-line-clamp: 3;
    // -webkit-box-orient: vertical;
    // overflow: hidden;

    a:is(:link, :visited) {
      color: inherit;
      text-decoration: underline;
    }

    &-button {
      cursor: pointer;
      color: $dark-green;
    }
  }
  &__dot {
    display: none;
    color: $dark-green;
  }
  &__form {
    display: grid;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: repeat(2, 1fr);
    column-gap: min(42px, 42/1920 * 100vw);
    row-gap: min(40px, 40/1080 * 100vh);
    width: 100%;
    height: 100%;
  }
  &__form-sended {
    position: absolute;
    top: min(-26px, -26/1920 * 100vw);
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    color: #fff;
    font-weight: 300;
    font-size: min(16px, 16/1920 * 100vw);
    letter-spacing: 0.4px;
    &.show {
      visibility: visible;
      opacity: 1;
    }
  }
  &__input-box {
    height: fit-content;
    &--message {
      grid-column: 1 / 3;
    }
    input {
      height: min(95px, 95/1080 * 100vh);
    }
    textarea {
      height: min(244px, 244/1080 * 100vh);
      resize: none;
    }
  }
}

@media (max-width: 992px) {
  .ContactForm {
    height: calc(100vh - var(--navHeight));
    &__background {
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: 100%;
      text-align: center;
    }
    &__container {
      top: 0;
      padding: 0;
      height: 100%;
    }
    &__side {
      &--left {
        padding: min(34px, 34/780 * 100vh) min(20px, 20/360 * 100vw);
      }
      &--right {
        padding: 0 min(20px, 20/360 * 100vw) min(34px, 34/780 * 100vh);
        width: 100%;
      }
    }

    //left
    &__address {
      font-size: min(16px, 16/360 * 100vw);
      line-height: min(23px, 23/360 * 100vw);
      letter-spacing: 0.4px;
      br {
        display: none;
      }
      p {
        margin: 0;
      }
    }
    &__header {
      margin-bottom: min(22px, 22/360 * 100vw);
      font-size: min(30px, 30/360 * 100vw);
      line-height: min(31px, 31/360 * 100vw);
      letter-spacing: 0.75px;
      white-space: initial;
    }

    //right
    &__button {
      width: min(328px, 328/360 * 100vw);
      height: min(50px, 50/780 * 100vh);
      font-size: min(16px, 16/360 * 100vw);
      &-box {
        grid-column: unset;
      }
    }
    &__clause {
      grid-column: unset;
      font-size: min(12px, 12/360 * 100vw);
      line-height: min(18px, 18/360 * 100vw);
      letter-spacing: 0.3px;
    }
    &__dot {
      display: inline;
    }
    &__form {
      grid-template-rows: repeat(2, auto) 1fr repeat(2, auto);
      grid-template-columns: 1fr;
      column-gap: min(42px, 42/1920 * 100vw);
      row-gap: min(42px, 42/1080 * 100vh);
    }
    &__form-sended {
      font-size: min(16px, 16/360 * 100vw);
      letter-spacing: -0.16px;
    }
    &__input-box {
      &--message {
        grid-column: unset;
      }
      input {
        height: min(50px, 50/780 * 100vh);
      }
      textarea {
        height: min(128px, 128/780 * 100vh);
        resize: none;
      }
    }

    &__clause-full {
      height: fit-content;
    }
  }
}
