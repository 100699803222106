@import 'variables';

.AboutUs {
    height: calc(100vh - var(--navHeight));

    &__side {
        &--right {
            position: relative;
            overflow: hidden;
        }
    }
    //left
    &__box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: calc(120/1920*100%*2);
    }
    &__text-big {
        font-size: min(72px, 72/1920*100vw);
        line-height: min(72px, 72/1920*100vw);
        font-weight: 500;
        letter-spacing: -1.8px;
        width: 85%;
    }
    &__text-small {
        font-size: min(30px, 30/1920*100vw);
        font-weight: 300;
        letter-spacing: -0.75px;
        width: 72%;
    }
    //right
    &__bg {
        width: 100%;
        height: 100%;
        // top: -10%;
        // right: -24%;
        background-image: url('../../public/images/about-us-bg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        position: absolute;
        z-index: -1;
        // transition: transform .4s ease-out;
    }
    &__right-rows {
        width: 72%;
        >div:not(:first-of-type) {
            margin-top: min(50px, 50/1920*100vw);
        }
    }
    &__text-green {
        font-size: min(72px,72/1920*100vw);
        font-weight: 500;
        letter-spacing: -1.8px;
        color: $dark-green;
    }
    &__text-white {
        font-size: min(30px, 30/1920*100vw);
        font-weight: 400;
        letter-spacing: min(-0.75px, -0.75/1920*100vw);
        color: #fff;
    }
}

@media (max-width: 992px) {
    .AboutUs {
        text-align: center;
        .container {
            grid-template-columns: auto;
            grid-template-rows: 50% 50%;
        } 
        // left
        &__box {
            padding: 0;
        }
        &__text-big {
            font-size: min(30px, 30/360*100vw);
            line-height: min(30px, 30/360*100vw);
            letter-spacing: -0.75px;
            width: 100%;
        }
        &__text-small {
            font-size: min(19px, 19/360*100vw);
            letter-spacing: -0.475px;
            line-height: 23px;
            width: 100%;
        }
        //right
        &__right-rows {
            width: 100%;
            >div:not(:first-of-type) {
                margin-top: min(28px, 28/360*100vw);
            }
        }
        &__text-green {
            font-size: min(30px,30/360*100vw);
            letter-spacing: -0.75px;
        }
        &__text-white {
            font-size: min(19px, 19/360*100vw);
            font-weight: 300;
            letter-spacing: -0.475px;
        }
    }
}
